.Sentence {
    margin-top: 122px;
    text-align: center;
    color: var(--grey)
}
.Sentence__title {
    margin-top: -12px;
}

.Sentence__subtitle {
line-height: 1.8;
margin-top: -10px;
font-weight: 500;
}

@media screen and (max-width: 700px) {
    .Sentence__title {
        justify-content: center;
    }
    .Sentence__title {
    padding-inline: 16px;

    }
    .Sentence__subtitle {
        padding-inline: 16px;
    
        }
}