.Sentence2 {
  margin-top: 120px;
}

.Sentence2__title__h2 {
  display: flex;
  justify-content: center;
}

.Sentence2__title__h2 h2 {
  position: relative;
  z-index: 100;
  color: var(--grey);
}
.Sentence2__title__h2 h2 span {
  position: relative;
  z-index: 100;
  color: var(--dark);
}

.Sentence2__positioning__whiteblock {
  display: flex;
  justify-content: center;
}

.Sentence2__whitebackground {
  position: relative;
  z-index: 99;
  background-color: var(--grey);
  margin-top: -57px;
  margin-left: 405px;
  height: 33px;
  width: 150px;
  rotate: -2deg;
}

.Sentence2__cta {
  display: flex;
  justify-content: center;
}
.Sentence2__subtitle1 {
  text-align: center;
  margin-top: -10px;
  color: var(--grey);
  font-weight: 500;
  line-height: 1.8;
}
.Sentence2__subtitle2 {
  text-align: center;
  line-height: 1.8;
  color: var(--grey);
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 45px;
}
@media screen and (max-width: 1000px) {

  .Sentence2__title__h2 h2 {
text-align: center;
        z-index: 100;
    color: var(--grey);
  }
  .Sentence2__positioning__whiteblock {
    display: none;
  }
  .Sentence2__title__h2 h2 span {
    position: relative;
    z-index: 100;
    color: white;
  }
  .Sentence2__subtitle {
    padding-inline: 24px;
  }
}