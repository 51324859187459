.Headerhome__burger {
  display: none;
}
.Headerhome_login {
  cursor: pointer;
  border: solid 1px var(--grey);
  width: 93px;
  height: 37px;
  margin-top: 26px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
}

.Headerhome {
  color: white;
  display: flex;
  flex-direction: row;
  height: 80px;
}

.Headerhome_logo img {
  cursor: pointer;
  width: 200px;
  margin-left: 150px;
  margin-top: 10px;
  margin-right: 16px;
}
.Headerhome__verticallign {
  margin-right: 37px;
  margin-left: 6px;
  margin-top: 34px;
  height: 24px;
  width: 1px;
  background-color: var(--grey);
}

.Headerhome_right {
  margin-left: auto;
  text-align: right;
  display: flex;
  flex-direction: row;
  margin-right: 160px;
}

.Headerhome_icon_login {
  color: var(--grey);
  margin-left: 8px;
  margin-top: 6px;
  scale: 0.9;
}
.Headerhome_login_text p {
  font-size: 16px;
  color: var(--grey);
  margin-left: 6px;
  margin-top: 8px;
}

.Headerhome_right__link {
  margin-top: 20px;
  margin-right: 30px;
}
.Headerhome_right__link p {
  font-size: 16px;
  font-weight: 800;
}

@media screen and (max-width: 950px) {
  .Headerhome__burger {
    display: block;
    margin-right: 16px;
    margin-left: auto;
    margin-top: 10px;
  }
  .Headerhome_logo img {
    cursor: pointer;
    width: 200px;
    margin-left: 1px;
    margin-top: 10px;
    margin-right: 16px;
  }

  .Headerhome_right {
    display: none;
  }

  .Headerhome_icon_language {
    display: none;
  }
  .Headerhome_login {
    display: none;
  }
  .Headerhome_icon_login {
    display: none;
  }
  .Headerhome_login_text {
    display: none;
  }
}
