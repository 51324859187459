.CardClass {
  background-color: var(--dark);
  width: 100%;
  border-radius: 5px;
  height: 200px;
  display: flex;
  flex-direction: row;
}

.CardClass__left {
  padding-top: 10px;
  padding-bottom: 10px;
  flex: 1;
}

.CardClass__right {
  flex: 1;
  margin-left: 16px;
}
.CardClass__right__title {
  color: var(--grey);
}
.CardClass__right__subtitle {
  margin-top: -17px;
  font-weight: 500;
  color: var(--grey2);
}
.CardClass img {
  height: 100%;
}
.CardClass__right__badge {
  margin-top: -4px;
}
.CardClass__right__bottom {
  padding-top: 80px;
}
.CardClass__right__bottom__arrow {

  color: var(--grey);
  margin-right: 10px;
  display: flex;
  justify-content: right;
}

@media screen and (max-width: 1285px) {

  .CardClass img {
    height: 80%;
  }
  .CardClass {
    background-color: var(--dark);
    width: 100%;
    border-radius: 5px;

    height: 200px;
    display: flex;
    flex-direction: row;
  }
  .CardClass__left {
    padding-top: 10px;

    padding-bottom: 10px;
    max-width: 140px;
  }
  .CardClass__right {
    width: 150px;

    margin-left: 16px;
  }
  .CardClass__right__bottom__arrow {
    scale: 1;
    color: var(--grey);
    margin-right: 10px;  


    display: flex;
    justify-content: right;
  }
}
