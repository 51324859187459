.ComparisonTable {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.ComparisonTable__line1 {
  height: 50px;
  width: 300px;
  display: flex;
  flex-direction: row;
  margin-bottom: -14px;
}
.ComparisonTable__line {
  margin-top: -10px;
  margin-bottom: -10px;
  height: 100px;
  width: 300px;
  display: flex;
  flex-direction: row;
}
.ComparisonTable__line1 p {
  font-size: 9px;
  font-weight: 700;
  letter-spacing: -0em;
  font-family: "Bricolage Grotesque", sans-serif;
  color: var(--grey);
}

.ComparisonTable__title__emptyblock {
  flex: 1;
}
.ComparisonTable__title1 {
  display: flex;
  justify-content: center;
  flex: 1;
}
.ComparisonTable__title2 {
  display: flex;
  justify-content: center;
  flex: 1;
}
.ComparisonTable__title3 {
  display: flex;
  justify-content: center;
  flex: 1;
}

.ComparisonTable__logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ComparisonTable__logo img {
  margin-left: -20px;
  max-height: 50px;
  max-width: 50px;
}
.ComparisonTable__icon__check {
  color: #5ec169;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ComparisonTable__icon__close {
  color: #dd524c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.ComparisonTable__greyline {
  height: 1px;
  width: 100%;
  background-color: #444342;
}
